@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";

@media (min-width: 885px) {
  .form {
    .dialog {
      display: flex;
      width: 1300px;
      padding: 0px 20px 10px 20px;
    }

    .otherQuestions {
      padding: 0px 20px 10px 20px;
      color: $planr-font-color;
      display: flex;
      .questionCol {
        width: 50%;
        &:first-child {
          margin-right: 30px;
        }
        .newQuestion {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
        }
        .questionItems {
          border: 2px solid $standard-planr-gray-color;
          border-radius: 4px;
          max-height: 200px;
          overflow-y: auto;
          background-color: $standard-planr-gray-color;
          padding: 8px;
        }
      }
    }
    .questionRow,
    .questionRowParent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #fff;
      border-radius: 4px;
      transition: 0.2s ease-in-out;
      padding: 5px 10px;
      margin-bottom: 8px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: $light-planr-gray;
      }
      .number {
        width: 120px;
      }
      .description {
        width: 320px;
      }
      .status {
        width: 100px;
        color: #fff;
        font-size: 12px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    // .questionRowParent {
    //   border: 2px solid $standard-planr-gray-color;
    //   border-radius: 4px;
    // }
    .typeRadio {
      svg {
        cursor: pointer;
        margin-right: $standard-padding;

        path {
          fill: $standard-gray;
        }
      }

      svg.active {
        path {
          fill: $standard-blue;
        }
      }
    }

    .statusInput {
      width: 100%;
    }

    :global {
      .bp3-dialog-body > div {
        flex: none;
      }

      .bp3-inline .bp3-label {
        min-width: 120px;
        color: $standard-planr-darkblue;
        font-family: "GothamPro", Arial, sans-serif;
      }

      .bp3-form-content {
        width: 100%;
      }

      .dnd-uploader {
        min-height: 60px;
        margin-bottom: 5px;
      }
    }
  }

  .columnHeader {
    font-size: $planr-font-color;
    font-size: 16px;
    display: flex;
    font-weight: 400;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .columnMid {
    padding: 10px;
    width: 30%;
    margin-left: 16px;
    border: 2px solid $standard-planr-gray-color;
    border-radius: 4px;
  }
  .columnRight {
    padding: 10px;
    width: 40%;
    margin-left: 16px;
    border: 2px solid $standard-planr-gray-color;
    border-radius: 4px;
  }
  .columnLeft {
    padding: 10px;
    width: 30%;
    border: 2px solid $standard-planr-gray-color;
    border-radius: 4px;
  }
}

@media (max-width: 885px) {
  .form {
    .dialog {
      display: flex;
      flex-direction: column;
      padding: 10px;
      align-items: center;
    }
    .typeRadio {
      svg {
        cursor: pointer;
        margin-right: $standard-padding;

        path {
          fill: $standard-gray;
        }
      }

      svg.active {
        path {
          fill: $standard-blue;
        }
      }
    }

    .statusInput {
      width: 100%;
    }

    :global {
      .bp3-dialog-body {
        flex-direction: column;
      }

      .bp3-inline .bp3-label {
        min-width: 120px;
        color: $standard-planr-darkblue;
        font-family: "GothamPro", Arial, sans-serif;
      }

      .bp3-form-content {
        width: 100%;
      }

      .dnd-uploader {
        min-height: 60px;
        margin-bottom: 5px;
      }
    }
  }
  .columnRight {
    width: 99%;
  }
  .columnMid {
    width: 99%;
  }
  .columnLeft {
    width: 99%;
  }
}

.messages {
  background-color: $standard-planr-gray-color;
  border-radius: 4px;
  width: 100%;
  color: $planr-font-color;
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;
  .message {
    background-color: #fff;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
    padding: 5px;
    margin: 8px;
    position: relative;
    .messageBtn {
      transition: 0.2s ease-in-out;
      opacity: 0;
      position: absolute;
      width: 0px;
      right: 57px;
      top: 0px;
    }
    &:hover {
      .messageBtn {
        transition: 0.2s ease-in-out;
        opacity: 1;
        width: 20px;
        position: absolute;
        right: 50px;
        top: 0px;
      }
    }
    .messageHeader {
      color: $planr-lightblue-color;
      margin-bottom: 3px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.msgs {
  width: 270px;
  line-height: 14px;
  .message {
    transition: 0.2s ease-in-out;
    margin-bottom: 5px;
    width: 100%;

    .messageHeader {
      font-size: 10px;
      color: $planr-lightblue-color;

      display: flex;
      justify-content: space-between;
    }
  }
}

.fileList {
  overflow: auto;

  label {
    margin-bottom: 5px;
  }
}

.filesList {
  margin-top: 5px;
}
